import gooogleSheetsLogo from '../images/google-sheets-logo.svg'
import shopifyLogo from '../images/shopify-logo.png'
import gorgiasLogo from '../images/gorgias-logo.png'

// A real Pandium customer using the embedded auth-only or integration-install experience
// would wire up their front end to fetch the integrations from the Pandium API
// But GWT exists just to demo the embedded functionality,
// so there's not much of a point in building out a full non-IMP marketplace.
export const integrations: Integration[] = [
    {
        name: 'la2gd',
        id: 943,
        namespace: 'staging-gwt',
        longName: 'Google Sheets',
        archived: false,
        marketplaceSettings: {
            devURL: 'https://support.google.com/docs?hl=en&p=#topic=1382883',
            docURL: 'https://www.google.com/intl/en_US/docs/about/',
            paused: true,
            schedule: '0 1 * * *',
            published: true,
            orderIndex: null,
            supportURL:
                'https://support.google.com/docs?hl=en&p=#topic=1382883',
            externalURL: '',
            primaryLogo: gooogleSheetsLogo,
            secondaryLogo:
                'https://static.dev-andrea.pandium.com/gwt/integrations/943/logos/943_secondaryLogo.png',
            longDescriptions: [
                {
                    Subtitle: 'See your stuff anywhere',
                    Description:
                        '<p>Quickly invite others to view, download, and collaborate on all the files you want–no email attachment needed. you can quickly invite others to view, download, and collaborate on all the files you want–no email attachment needed.</p>',
                },
            ],
            tenantShowContent: '',
            cardShortDescription:
                'Your files in Drive can be reached from any smartphone, tablet, or computer. So wherever you go, your files follow.',
            detailShortDescription:
                'Your files in Drive can be reached from any smartphone, tablet, or computer. So wherever you go, your files follow.',
            syncScheduleDescription: '',
            connectionSettingsContent: '',
            connectYourAccountsDescription: '',
        },
        type: 'Internal',
        categories: [
            { name: 'Cows', id: 243, description: null, order_index: 14 },
            {
                name: 'Things I Hate--The Irish',
                id: 248,
                description: null,
                order_index: 29,
            },
        ],
        tags: [],
        flag: null,
        approvalStatus: 'Approved',
    },
    {
        archived: false,
        name: 'shopify',
        namespace: 'staging-gwt',
        longName: 'Shopify',
        marketplaceSettings: {
            cardShortDescription:
                'Sync orders from shopify, so tracking can be uploaded into your Shopify.',
            paused: true,
            schedule: '*/30 * * * *',
            published: true,
            isFeatured: false,
            orderIndex: null,
            primaryLogo: shopifyLogo,
            secondaryLogo:
                'https://static.dev-andrea.pandium.com/gwt/integrations/1469/logos/Shopify_1.png',
            longDescriptions: [],
            tenantShowContent: '',
            syncScheduleDescription: '',
            connectionSettingsContent: '',
            connectYourAccountsDescription: '',
        },
        connectors: [{ name: 'shopify', isGlobal: false }],
        syncScheduleTimes: [
            { cron: '*/30 * * * *', schedule: 'Every 30 minutes' },
            { cron: '5 */1 * * *', schedule: 'Every 1 Hour' },
            { cron: '20 */12 * * *', schedule: 'Every 12 Hours' },
            { cron: '0 1 * * *', schedule: 'Once Per Day' },
        ],
        repositoryPath: 'pandium/test-integration',
        repositoryTrackingBranch: 'master',
        repositoryUrl: 'https://github.com/pandium/integrations.git',
        type: 'Internal',
        categories: [],
        tags: [],
        flag: null,
        partnerId: null,
        approvalStatus: 'Approved',
        submittedDate: null,
        discussion: { partnerName: 'gwt', enableSubmit: true },
        defaultReleaseChannel: null,
        id: 1469,
        defaultReleaseId: null,
        createdDate: '2025-02-06T18:50:44.285095',
        modifiedDate: '2025-02-07T20:57:58.545217',
        partner: null,
    },
    {
        archived: false,
        name: 'shipbob',
        namespace: 'staging-gwt',
        longName: 'Gorgias',
        marketplaceSettings: {
            cardShortDescription:
                'Sync Customers and their feedback into Gorgias. Gorgias responses to that feedback will be synced back.',
            paused: true,
            schedule: '*/30 * * * *',
            published: true,
            isFeatured: false,
            orderIndex: null,
            primaryLogo: gorgiasLogo,
            secondaryLogo:
                'https://static.dev-andrea.pandium.com/gwt/integrations/1447/logos/gorgias.png',
            longDescriptions: [],
            tenantShowContent: '',
            syncScheduleDescription: '',
            connectionSettingsContent: '',
            connectYourAccountsDescription: '',
        },
        connectors: [{ name: 'gorgias-oauth', isGlobal: false }],
        syncScheduleTimes: [
            { cron: '*/30 * * * *', schedule: 'Every 30 minutes' },
            { cron: '5 */1 * * *', schedule: 'Every 1 Hour' },
            { cron: '20 */12 * * *', schedule: 'Every 12 Hours' },
            { cron: '0 1 * * *', schedule: 'Once Per Day' },
        ],
        repositoryPath: '/pandium/test-integration',
        repositoryTrackingBranch: 'master',
        repositoryUrl: 'https://github.com/pandium/integrations',
        type: 'Internal',
        categories: [
            {
                name: 'Candies',
                namespace: 'staging-gwt',
                description: null,
                label_type: 'Category',
                flag_type: null,
                flag_color: null,
                id: 250,
                created_date: '2023-05-05T16:32:06.452082',
                modified_date: '2023-05-30T14:11:26.989455',
                show: true,
                order_index: 20,
            },
        ],
        tags: [],
        flag: null,
        partnerId: null,
        approvalStatus: 'Approved',
        submittedDate: null,
        discussion: { partnerName: 'gwt', enableSubmit: true },
        defaultReleaseChannel: null,
        id: 1447,
        defaultReleaseId: 1285,
        createdDate: '2023-12-19T16:57:49.352406',
        modifiedDate: '2025-01-30T16:49:33.636987',
        partner: null,
    },
]

export interface Integration {
    archived: boolean
    name: string
    namespace: string
    longName: string
    marketplaceSettings: {
        paused: boolean
        schedule: string
        published: boolean
        isFeatured?: boolean
        orderIndex: any
        primaryLogo: string
        secondaryLogo: string
        longDescriptions: any[]
        tenantShowContent: string
        syncScheduleDescription: string
        connectionSettingsContent: string
        connectYourAccountsDescription: string
        devURL?: string
        docURL?: string
        supportURL?: string
        externalURL?: string
        cardShortDescription?: string
        detailShortDescription?: string
    }
    connectors?: {
        name: string
        isGlobal: boolean
    }[]
    syncScheduleTimes?: {
        cron: string
        schedule: string
    }[]
    repositoryPath?: string
    repositoryTrackingBranch?: string
    repositoryUrl?: string
    type: string
    categories: any[]
    tags: any[]
    flag: any
    partnerId?: any
    approvalStatus: string
    submittedDate?: any
    discussion?: {
        partnerName: string
        enableSubmit: boolean
    }
    defaultReleaseChannel?: any
    id: number
    defaultReleaseId?: any
    createdDate?: string
    modifiedDate?: string
    partner?: any
}
