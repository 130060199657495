import { Grid, Box, Typography, Card, CardContent, Button } from '@mui/material'
import { integrations, Integration } from './integrations'
import { useState } from 'react'

const AuthOnlyMarketplace: React.FC<{ authOnlySettings: string }> = ({
    authOnlySettings,
}) => {
    return (
        <>
            <Grid
                container
                spacing={4}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                style={{ marginTop: '40px' }}
            >
                {integrations.map((integration) => (
                    <IntegrationCard
                        integration={integration}
                        authOnlySettings={authOnlySettings}
                    />
                ))}
            </Grid>
        </>
    )
}

const IntegrationCard: React.FC<{
    integration: Integration
    authOnlySettings: string
}> = ({ integration, authOnlySettings }) => {
    const [isConnected, setIsConnected] = useState(false)

    const handleClick = () => {
        const [authConnector, authIntegration] = authOnlySettings.split('|')
        window.open(
            `${process.env.REACT_APP_MARKETPLACE_LINK}?connector_name=${authConnector}&integration_name=${authIntegration}`,
            '_blank',
            `width=800,height=700,left=100,top=100,scrollbars,resizable`
        )

        //TODO: how to be aware that the connection was succesful, so that we only set isConnected to true for a successful connection?
        // poll the tenant?
        setIsConnected(true)
    }

    return (
        <Grid item key={integration.id}>
            <Card
                sx={{
                    height: '440px',
                    width: '300px',
                    borderRadius: '4px',
                    boxShadow:
                        '0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1);',
                    '&:hover': {
                        boxShadow:
                            '0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12);',
                    },
                    '&:active': {
                        boxShadow:
                            '0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);',
                    },
                    cursor: 'pointer',
                }}
                className={'pandium-integration-card'}
            >
                <Box
                    sx={{
                        backgroundColor: 'rgb(244, 244, 244)',
                        height: '150px',
                        padding: '35px',
                        margin: '10px',
                        position: 'relative',
                        borderRadius: '4px',
                    }}
                >
                    <Box
                        component="img"
                        src={integration.marketplaceSettings.primaryLogo}
                        sx={{
                            objectFit: 'contain',
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </Box>
                <CardContent
                    sx={{
                        height: '160px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        {integration.longName}
                    </Typography>

                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                    >
                        {integration.marketplaceSettings.cardShortDescription}
                    </Typography>
                        <Button
                            className={'pandium-card-button'}
                            size="small"
                            sx={{ fontWeight: 600 }}
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleClick}
                        >
                            {isConnected? 'Disconnect' :'Connect'}
                        </Button>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default AuthOnlyMarketplace
