import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import ImpFrame from './components/ImpFrame'
import Dashboard from './images/main-dashboard.jpg'
import Header from './components/Header'
import Footer from './components/Footer'
import Settings from './components/Settings'
import AuthOnlyMarketplace from './components/AuthOnlyMarketplace'

import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import gwtLogo from './images/greenwoodtech-logo.png'

export default function Router() {
    const [headerLogo, setHeaderLogo] = useState()
    const [demoMode, setDemoMode] = useState('marketplace')
    const [authOnlySettings, setAuthOnlySettings] = useState('')

    useEffect(() => {
        const updates = JSON.parse(
            sessionStorage.getItem('layoutUpdate') || '{}'
        )
        setHeaderLogo(updates?.headerLogo || gwtLogo)
    }, [])

    // Dont render until logo is grabbed from session storage if it exists to avoid default logo flicker
    if (!headerLogo) {
        return null
    }

    const router = createBrowserRouter([
        {
            path: '/',
            element: (
                <>
                    <Header logo={headerLogo} demoMode={demoMode} />
                    <Box className="body">
                        <img
                            id="Demo Dashboard"
                            src={Dashboard}
                            alt="Demo Dashboard"
                            style={{
                                width: '100%',
                                margin: 'auto',
                            }}
                        />
                    </Box>
                    <Footer />
                </>
            ),
        },
        {
            path: '/integrations',
            element: (
                <>
                    <Header logo={headerLogo} demoMode={demoMode} />
                    <Box className="body">
                        {demoMode === 'marketplace' && (
                            <ImpFrame
                                path={process.env.REACT_APP_MARKETPLACE_LINK}
                            />
                        )}
                        {demoMode === 'auth-only' && (
                            <AuthOnlyMarketplace
                                authOnlySettings={authOnlySettings}
                            />
                        )}
                    </Box>
                    <Footer />
                </>
            ),
        },
        {
            path: '/myapps',
            element: (
                <>
                    <Header logo={headerLogo} demoMode={demoMode} />
                    <Box className="body">
                        <ImpFrame
                            path={
                                process.env.REACT_APP_MARKETPLACE_LINK +
                                '?my_apps=true'
                            }
                        />
                    </Box>
                    <Footer />
                </>
            ),
        },
        {
            path: '/settings',
            element: (
                <>
                    <Header logo={headerLogo} demoMode={demoMode} />
                    <Box className="body">
                        <Settings
                            setHeaderLogo={setHeaderLogo}
                            setDemoMode={setDemoMode}
                            setAuthOnlySettings={setAuthOnlySettings}
                        />
                    </Box>
                    <Footer />
                </>
            ),
        },
    ])

    return <RouterProvider router={router} />
}
