import {
    Button,
    TextField,
    Typography,
    Container,
    Box,
    RadioGroup,
    Radio,
    FormLabel,
    FormControlLabel,
} from '@mui/material'
import React from 'react'

const Settings: React.FC<{
    setHeaderLogo: any
    setDemoMode: any
    setAuthOnlySettings: any
}> = ({ setHeaderLogo, setDemoMode, setAuthOnlySettings }) => {
    const handleDemoModeSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const formData = new FormData(event.currentTarget)
        const demoMode = formData.get('demoMode') as string
        setDemoMode(demoMode)
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const formData = new FormData(event.currentTarget)
        const headerLogo = formData.get('headerLogoUrl') as string
        sessionStorage.setItem(
            'layoutUpdate',
            JSON.stringify({ headerLogo: headerLogo })
        )
        setHeaderLogo(headerLogo)
    }

    const handleAuthOnlySubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const formData = new FormData(event.currentTarget)
        const integrationName = formData.get('integrationName') as string
        const connectorName = formData.get('connectorName') as string
        setAuthOnlySettings(`${connectorName}|${integrationName}`)
    }

    return (
        <Container maxWidth="md">
            <Box className="settings-form">
                <Typography variant="h5">Set Demo Mode</Typography>
                <form onSubmit={handleDemoModeSubmit}>
                    <FormLabel id="demo-radio-buttons-group-label">
                        Demo Mode
                    </FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="marketplace"
                        name="demoMode"
                    >
                        <FormControlLabel
                            value="marketplace"
                            control={<Radio />}
                            label="In-App Marketplace"
                        />
                        <FormControlLabel
                            value="integration-install"
                            control={<Radio />}
                            label="Embedded Integration Install"
                        />
                        <FormControlLabel
                            value="auth-only"
                            control={<Radio />}
                            label="Embedded Auth-Only Connections"
                        />
                    </RadioGroup>
                    <Box className="submit-button-container">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            SET DEMO MODE
                        </Button>
                    </Box>
                </form>
                <Typography variant="h5">Set Header Logo</Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Header Logo Url"
                        name="headerLogoUrl"
                        variant="standard"
                        fullWidth
                        helperText="Rectangle logos with the name included work best"
                    />
                    <Box className="submit-button-container">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            SET LOGO
                        </Button>
                    </Box>
                </form>
                <Typography variant="h5">
                    Configure Embedded Auth Only Connection Demo
                </Typography>
                <Typography>
                    When this site is in "Emebedded Auth Only Connections" mode,
                    the connect button for any integration in the Apps &
                    Integrations tab will open the embedded auth-only experience
                    for the integration and connector specified here.
                </Typography>
                <form onSubmit={handleAuthOnlySubmit}>
                    <TextField
                        label="Integration Name"
                        name="integrationName"
                        variant="standard"
                        fullWidth
                    />
                    <TextField
                        label="Connector Name"
                        name="connectorName"
                        variant="standard"
                        fullWidth
                    />
                    <Box className="submit-button-container">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            SET AUTH-ONLY CONFIGURATION
                        </Button>
                    </Box>
                </form>
            </Box>
        </Container>
    )
}

export default Settings
